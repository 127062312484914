<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="dialog"
    scrollable
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small  color="secondary" v-bind="attrs" v-on="on"
        >
        <v-icon left v-if="icon">
        {{icon}}
      </v-icon>
        <span v-if="text">{{text}}</span>
        <span v-else>Get Help / More Info</span>
        </v-btn
      >
    </template>
    <v-card>
      <v-toolbar color="primary" dark>
        <div v-if="article">{{ article.title }}</div>
        <div v-else>Loading...</div>
      </v-toolbar>
      <v-card-text class="pt-5">
        <div v-if="article">
          <div v-html="article.body"></div>
        </div>
        <div v-else-if="error"></div>
        <div v-else-if="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          Loading help content... Please stand by.
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// const instance = 'verify-help'
const instance = "verify-help";
const helpCenter = `https://${instance}.zendesk.com/api/v2/help_center/en-us/articles/`;

const articleMapping = {
  location: 360061042691,
  os: 360061038011,
  cpu: 360060641172,
  ram: 360061038071,
  architecture: 360060641172,
  displays: 360061038111,
  downloadSpeed: 360061038151,
  uploadSpeed: 360061038151,
  latency: 360061038151,
  jitter: 360061038151,
  headset: 360061038191,
  webcam: 360060641312,
  unsupported: 360061636011,
  mobile: 360061641331,
  macOSInfo: 360061248072,
  WindowsInfo: 360061247612,
  browserBlocked: 360062376351,
};

export default {
  props: ["id","icon", "text","large"],
  data() {
    return {
      article: null,
      loading: false,
      dialog: false,
      error: null,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.loadArticle();
      }
    },
  },
  methods: {
    async loadArticle() {
      this.error = null;
      if (!this.id || !articleMapping[this.id]) {
        return;
      }
      this.loading = true;
      let resp = await fetch(`${helpCenter}${articleMapping[this.id]}.json`);
      this.loading = false;
      if (resp.ok) {
        let data = await resp.json();
        this.article = data.article;
      } else {
        this.error =
          "There was an error loading this help desk article. Please try again in a few minutes";
      }
    },
  },
};
</script>

<style>
</style>