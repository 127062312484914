import device from './device'

const supportedBrowser = ['Chrome','Microsoft Edge']
const supportedOs = ['Windows',"macOS"]
const supportedDevice = ['desktop']

const supported = {
  os: supportedOs.indexOf(device.os.name) > -1,
  browser: supportedBrowser.indexOf(device.browser.name) > -1,
  device: supportedDevice.indexOf(device.platform.type) > -1,
}

const isSupported = supported.os && supported.browser && supported.device

export default  {
    overall: isSupported,
    os: supported.os,
    browser: supported.browser,
    device: supported.device,
    reason: !supported.device ? 'device' :  !supported.os ? 'os' : !supported.browser ? 'browser' : 'unknown',
    test:supportedBrowser.indexOf(device.browser.name),
    dbrowser: device.browser,
    supportedBrowser
    
}