<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Unsupported 
          <span v-if="$route.params.reason === 'device'">Device</span>
        <span v-else-if="$route.params.reason === 'os'">Operating System</span>
        <span v-else-if="$route.params.reason === 'browser'">Browser</span>

      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p v-if="$route.params.reason === 'device'">
        Unfortunately, you are trying to access this site from an unsupported
        device ({{ device.platform.type }}). This site must be accessed on the
        same device you would be using for work.
      </p>
      <p v-else-if="$route.params.reason === 'os'">
        Unfortunately, you are trying to access this site from an unsupported
        operating system ({{ device.os.name }}). This site must be accessed on
        the same device you would be using for work and it must be running
        Windows or MacOS.
      </p>
      <p v-else-if="$route.params.reason === 'browser'">
        Unfortunately, you are trying to access this site from an unsupported
        browser ({{ device.browser.name }}). This site must be accessed on a
        supported browser like
        <a href="https://www.google.com/chrome" target="_blank"
          >Google Chrome</a
        >.
      </p>
            <p v-else-if="$route.params.reason === 'browserVersion'">
        Unfortunately, you are trying to access this site from an outdated browser ({{ device.browser.name }} - {{ device.browser.version }}). The good news is this is a simple fix.<br/><br/>Please update your browser, reboot, then come back and begin the process.
      </p>

      <p v-else>
        Unfortunately, you are trying to access this site from an unsupported
        system. This site must be accessed on the same device you would be using
        for work and it must be running Windows or MacOS.
      </p>
      <template v-if="$route.params.reason === 'device'">
        <p>
          You can this site to yourself so that you can run it on your home
          office computer by clicking the button below.
        </p>
        <p>
          <v-btn @click="share" color="primary" dark block>Share Link</v-btn>
        </p>
      </template>
      <v-btn color="primary">
      <Article id="unsupported" icon="computer" text="Learn More" /></v-btn>
      <!-- {{device}} {{isSupported}} -->
    </v-card-text>
  </v-card>
</template>

<script>
import isSupported from "../utils/isSupported";
import device from "../utils/device";
import Article from '../components/Article'

export default {
  data() {
    return {
      device,
      isSupported
    };
  },
  components:{
    Article
  },
  methods: {
    share() {
      if (navigator.share) {
        navigator
          .share({
            title: "VIPdesk Home Office Assessment",
            url: "https://verify.vipdesk.com",
            text: `Thank you for your interest in VIPdesk. 
Please run this check (https://verify.vipdesk.com) on your home office computer. We need to test both your home office computer and internet connection. Once you are done and passed, please apply online at https://vipdesk.com/careers/current-openings.`,
          })
          // .then(() => {
          //   console.log("Thanks for sharing!");
          // })
          // .catch((err) => {
          //   console.log("not sharing", err);
          // });
        // Web Share API is supported
      } else {
        // Fallback
      }
    },
  },
};
</script>

<style>
</style>